<template>
  <service-policy-edit
    serviceName="dnscf"
    storeModuleName="dnscf"
    pathName="cf"
    :ruleId="ruleId"
    :defaultRule="rule"
    :actionValidator="actionValidator"
    editionPath="/ds"
    :showSchedule="false"
    storePath="dnssecure"
    :maxlenRuleName="maxlenRuleName"
  >
    <template v-slot:main-card="slotProps">
      <main-dnscf-config-card
        :rule="slotProps.rule"
        @rule-change="slotProps.handleRuleChange"
      ></main-dnscf-config-card>
    </template>
    <template v-slot:rule-config-card="slotProps">
      <rule-config-card
        :rule="slotProps.rule"
        :categoryOptions="categoryOptions"
        @rule-change="slotProps.handleRuleChange"
      ></rule-config-card>
    </template>
  </service-policy-edit>
</template>

<script>
import Vue from "vue";
import { Form, FormItem, Input, Button } from "element-ui";
import ServicePolicyEdit from "@/components/ServicePolicies/ServicePolicyEdit";
import MainCard from "./components/MainCard";
import RuleConfigCard from "./components/RuleConfigCard";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "dns-cf-edit",
  components: {
    "el-form": Form,
    "el-input": Input,
    "el-button": Button,
    "el-form-item": FormItem,
    "main-dnscf-config-card": MainCard,
    "rule-config-card": RuleConfigCard,
    "service-policy-edit": ServicePolicyEdit,
  },
  data() {
    return {
      maxlenRuleName : 64,
      ruleId: undefined,
      rule: {
        rule_name: "",
        status: false,
        action: { type: null, value: null },
        source: [],
        categories: [],
        urls: [],
      },
    };
  },
  computed: {
    ...mapGetters("ispServices/categoryManagement", ["categories"]),
    categoryOptions() {
      let cats = this.categories.filter(function (item) {
        return item.category_type === 'internal';
      });
      if (!cats) return [];

      return cats.map(
        ((item) => ({
          name: item.isp_internal_category_id,
          value: this.t(item.isp_internal_category_id),
        })).bind(this)
      );
    },
  },
  methods: {
    ...mapActions("ispServices/categoryManagement", ["getCategories"]),
    actionValidator(rule, value, callback) {
      if (value.type && value.type !== "") callback();
      else callback(new Error(this.t("please select an action")));
    },
  },
  async created() {
    const ruleId = this.$route.params.pathMatch;
    if (ruleId && ruleId !== "") this.ruleId = ruleId;
    this.getCategories();
  },
};
</script>
