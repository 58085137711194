<template>
  <card class="padding-1">
    <h6 class="policy-card-title">
      <svgicon class="icon" icon="rule-conditions" width="16" height="16" />
      <span style="padding-left:1em">{{t('rule conditions')}}</span>
    </h6>
    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('source')" class="label-in-top">
          <p>
            {{this.t('Add one or more')}} <span class="ipv4-word">{{this.t('ipv4')}}</span> {{this.t('or')}} <span class="ipv6-word">{{this.t('ipv6')}}</span> {{this.t("with or without mask. Leave empty for 'Any'")}}
          </p>
          <autocomplete-tag-input
            :checks="['ip']"
            :inputType="'Source'"
            :value='rule.source'            
            @change="handleChange($event, 'source')" 
            :placeholder="t('source')"
            :showdropdown='showDropDown'
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('categories')" class="label-in-top">
          <p>{{ this.t('cf_categories_help') }}</p>
          <autocomplete-tag-input
            :value='rule.categories'
            :options='categoryOptions'
            :disabled="rule.urls.length>0"            
            @change="handleChange($event, 'categories')"
            :placeholder="t('categories')"
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <el-form-item :label="t('domains')" class="label-in-top">
          <p>
            {{ this.t('add_one_or_more') }} <span class="urls-domain-word">{{this.t('domains')}}</span>.{{ this.t("Leave empty for 'Any'") }}         
          </p>
          <autocomplete-tag-input
            inputType="onlydomain"
            :value='rule.urls'            
            :disabled="rule.categories.length>0"
            @change="handleChange($event, 'urls')"            
            :placeholder="t('domains')"
            :showdropdown='showDropDown'          
          ></autocomplete-tag-input>
        </el-form-item>
      </div>
    </div>

    

    
  </card>
</template>

<script>
  import { Input, FormItem } from 'element-ui';
  import AutocompleteTagInput from '@/components/Inputs/AutocompleteTagInput.vue';
  export default {
    name: 'rule-config-card',
    components: {
      'el-input': Input,
      'el-form-item': FormItem,
      'autocomplete-tag-input': AutocompleteTagInput
    },
    props: {
      rule: {
        type: Object,
        default: () => {}
      },     
      categoryOptions: {
        type: Array,
        default: () => []
      }      
    },
    data() {
      return { 
        showDropDown : false       
      }
    },
    methods: {
      handleChange(event, input) {
        let updatedRule = _.cloneDeep(this.rule);
        switch(input){
          case  'categories':
            // Category input show the translation, so we need to convert to get the real value to send to the backend
            let tempEvent = event.map(element => element.value ? element.value : element);
            let selected = this.categoryOptions.filter(option => tempEvent.includes(option.value) || tempEvent.includes(option.name));
            updatedRule[input] = selected.map( item => item.name);
            break;
          default:
            updatedRule[input] = event;
        }        
        this.$emit('rule-change', updatedRule);
      },
      handleTimeLimitChange(value, mode) {
        let updatedRule = _.cloneDeep(this.rule);
        if (mode === 'value') updatedRule.time_limit.value = value;
        else if (mode === 'type') updatedRule.time_limit.type = value;
        this.$emit("rule-change", updatedRule);
      }
    }
  }
</script>

<style scoped lang="scss">
  .time-row {
    align-items: flex-end;
  }
  .time-select {
    margin-bottom: 14px;
  }
  .services-word {
    color: #5b136d;
  }
  .mac-word {
    color: #51a427;
  }
  .devices-word {
    color: #2c6fb4;
  }
  .ipv4-word {
    color: #c15050;
  }
  .ipv6-word {
    color: #c7892c;
  }
  .urls-domain-word {
    color: #51a427;
  }
  .not-word {
    color: #c15050;
  }
  .reg-word {
    color: #2c6fb4;
  }
  .not-reg-word {
    color: #8f2ca8;
  }
</style>
